@import "../../abstracts/variables";

@mixin flection {
  transition: all 0.6s;
  flex: 1;
  flex-basis: content;
}

.structView {
  height: 100%;
  width: 100%;
  position: relative;
  flex-wrap: wrap;
  align-content: stretch;
  display: flex;
  transition: all 0.6s;
  z-index: 9;
}

#gotostruct-modal-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;

  & > a {
    display: inline-flex;
  }

  button {
    padding: 8px 0;
    margin: $spacing-small 0;
  }
}

.flex-row-centered {

  .zoom-btns {
    height: 36px;
    width: 36px;
    margin: 0%;
    padding: 0%;
  }
}

.bin-note-table .table-body {
  width: 100%;
  overflow-y: auto;
  height: 100px;

  ::-webkit-scrollbar {
    width: 12px; /* width of the entire scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: orange; /* color of the tracking area */
  }

  ::-webkit-scrollbar-thumb {
    background-color: blue; /* color of the scroll thumb */
    border-radius: $cornerRadius-large; /* roundness of the scroll thumb */
    border: 3px solid orange; /* creates padding around scroll thumb */
  }

  table {
    width: 100%;
  }
}

.bin-note-table {
  table,
  td,
  th {
    border: 1px solid;
    border-color: $color-grainColour;
    border-collapse: collapse;
    text-align: center;
  }
}

#structView-detail-column {
  flex: 10 2 19%;
}

#structView-visualization-column {
  flex: 10 3 29%;
}

#structView-graph-column {
  flex: 10 5 49%;
}

#structView-event-column {
  flex: 10 10 99%;
}