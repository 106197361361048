@import '../../abstracts/variables';

.location-desc-container {
  width: 90%;
  margin-left: 5%;
  color: $color-black;
}

.location-picker-container {
  background-color: $color-grey2;
  width: 100%;
  height: 400px;
}

.location-input-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: $spacing-small;
  color: $color-black;

  > :nth-child(2) {
    margin-left: 20px;
  }

  * p {
    margin-bottom: 3px;
  }
}
