$headerbar-height: 40px;
$spacing-small: 5px;
$spacing-large: 10px;
$cornerRadius-small: 5px;
$cornerRadius-normal: 10px;
$cornerRadius-large: 20px;

// COLOURS
//basic
$color-primary: #163872;
$color-yellow: #ffe001;
$color-light-blue: #426896;
$color-white: #ffffff;
$color-grey1: #f2f2f2;
$color-grey2: #E5E6E9;
$color-grey3: #aaaaaa;
$color-grey4: #646464; //bin gray
$color-grey5: #333333;
$color-grey6: #2d2d2d; //sensor gray
$color-black: #000000;
$color-shadow: #0000007F;
//bin colours
$color-tempGreen: #3c915b;
$color-moistBlue: #0b54a6;
$color-sensorRed: #9e1818;
$color-sensorYellow: #d2d23c;
$color-sensorGreen: #008000;
$color-sensorPurple: #800080;
$color-grainColour: #b8c6d7;
//fan colours
$color-fanGreen: #8cc64e;
$color-fanBlue: #1212f6;
$color-fanRed: #f30808;
//warning colours
$color-warningBlack: #000000C0;
$color-warningYellow:#FFFF007F;