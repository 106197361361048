
  //Z indices. NOTE: if elements are are nested in different levels, z-index may not work as expected
  
  $z-svg: -1;
  $z-switch-options-after: 5;
  $z-dd-list: 10;  
  $z-dialog-wrapper-backdrop: 999;
  $z-headerbar: 999;
  $z-left-menu-button: 999;
  $z-edit-toolbar: 999;
  $z-struct-status-overlay-holder: 999;  
  $z-left-menu-overlay: 9999;
  $z-right-menu-overlay: 9999;
  $z-tooltip: 9999;
  $z-dialog-wrapper: 9999;  
  $z-notif-container: 99999;
  $z-gmap-pac-container: 99999;  
  $z-dialog-wrapper-modal: 9999999;  
  $z-loading-bg: 9999999999999;