@import '../../abstracts/variables';

.structBar {
  $menuOpenPad: 170px;
  $menuClosedPad: 110px;

  display: flex;
  background-color: $color-light-blue;
  width: 100%;
  height: $headerbar-height;
  box-sizing: border-box;
  color: $color-white;
  align-items: center;
  justify-content: space-between;
  flex: 0 1 auto;
  padding-right: $menuClosedPad;
  transition: padding-right 0.5s;

  #switch-struct-btn {
    width: 80px;
    height: 36px;
    padding: 0;
    margin-left: $menuClosedPad;
    transition: margin-left 0.5s;

    svg {
      height: 100%;
    }
  }

  .structStepper {
    height: 100%;
    display: flex;
    align-items: center;

    button {
      height: 36px;
      width: 36px;
      margin: 0;
      padding: 0;
      svg {
        display: block;
        margin: auto;
        width: 33%;
        height: 66%;
      }
    }
    span {
      display: inline-block;
      width: 200px;
      text-align: center;
      padding: 0 $spacing-large;
    }
    .btn-icon {
      transform: none;
    }
  }

  .spacer {
    width: 80px;
  }

  &.menuOpen {
    padding-right: $menuOpenPad;

    #switch-struct-btn {
      margin-left: $menuOpenPad;
    }
  }
}
