@import "../../abstracts/variables";
@import "../../abstracts/z-index";

@mixin cont-flex-row-center {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.overlayHolder {
    z-index: $z-struct-status-overlay-holder;
    opacity: 0.9;
    position: absolute;
}

.struct-status-overlay {
    width: 380px;
    background-color: $color-white;
    border-radius: $cornerRadius-normal;
    border-width: 5px;
    border-color: $color-black;
    border-style: solid;
    display: flex;
    flex-direction: column;

    .sso__title {
        @include cont-flex-row-center;
        margin: $spacing-small;
        color: $color-primary;
        font-weight: bold;
    }

    .sso__tmp-dtl {
        @include cont-flex-row-center;
        margin: $spacing-small;
        height: 132px;

        div:nth-child(1) {
            width: 50%;
            height: 80%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            > span {
                display: flex;
                align-items: center;
                height: 30px;
                color: $color-tempGreen;

                > span {
                    font-weight: bold;
                    margin-left: 0.2em;
                }

                &:nth-child(2) {
                    color: $color-moistBlue;
                    span {
                        color: $color-moistBlue;
                    }
                }

                svg {
                    margin: $spacing-small;
                    width: 30px;
                    fill: $color-tempGreen;
                }
            }
        }

        div:nth-child(2) {
            width: 50%;
            height: 100px;
        }
    }

    .sso__ntf-status-stripped-bg {
        background-image: url("../../../images/ms_paint_bg.png");
        background-repeat: repeat-x;
        height: 55px;

        span {
            font-size: 1.6em;
            color: $color-black;
            position: relative;
            float: left;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-style: italic;
        }
    }

    .status-light {
        color: $color-white;
    }

    .status-dark {
        color: $color-primary;
    }

    .sso__ntf-status {
        background-color: $color-sensorGreen;
        height: 55px;

        span {
            position: relative;
            float: left;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-style: italic;
        }
    }

    &.yellow {
        .sso__ntf-status {
            background-color: $color-sensorYellow;
        }
    }

    &.red {
        .sso__tmp-dtl {
            div:nth-child(1) {
                > span {
                    > span {
                        color: $color-grey4;
                    }
                    > svg {
                        fill: $color-grey4;
                    }
                }
            }
        }
        .sso__ntf-status {
            background-color: $color-sensorRed;
        }
    }

    .sso__remote-status {
        @include cont-flex-row-center;
        margin: 0px $spacing-small;
        height: 25px;
        color: $color-primary;

        .directVolt {
            width: 50px;
            fill: $color-black;
        }

        .batterySvg {
            width: 30px;
            height: 30px;
        }

        .warning {
            font-weight: bold;
            color: $color-sensorRed;
        }
    }
}
