@import "variables";
@import "z-index";


#sharedSvgDefinitions {
    position: absolute;
    z-index: $z-svg;

    #cautionFill,
    #bagFill {
        line {
            stroke: $color-warningBlack;
            stroke-width: 10;
        }
        rect {
            fill: $color-warningYellow;
        }
    }

    #binGradFill {
        stop {
            stop-opacity: 1;
            &:nth-child(1) {
                stop-color: $color-grey2;
            }
            &:nth-child(2) {
                stop-color: $color-grey3;
            }
        }
    }

    #bagSubGrad {
        stop {
            stop-opacity: 1;
            &:nth-child(1) {
                stop-color: $color-grey1;
            }
            &:nth-child(2) {
                stop-color: $color-grey2;
            }
            &:nth-child(3) {
                stop-color: $color-grey3;
            }
            &:nth-child(4) {
                stop-color: $color-grey5;
            }
        }
    }

    #bagFill {
        path {
            fill: url(#bagSubGrad);
            stroke: $color-grey3;
            stroke-width: 0.005;
        }
    }

    @mixin fan-grad {
        stop {
            stop-opacity: 1;
            &:nth-child(2) {
                stop-color: $color-grey1;
            }
        }
    }

    #fanErrorGrad {
        @include fan-grad();
        stop:nth-child(1) {
            stop-color: $color-fanRed;
        }
    }

    #fanRunningGrad {
        @include fan-grad();
        stop:nth-child(1) {
            stop-color: $color-fanBlue;
        }
    }

    #fanStartGrad {
        @include fan-grad();
        stop:nth-child(1) {
            stop-color: $color-fanGreen;
        }
    }
}
