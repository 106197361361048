/// Allows you to style input placeholders with cross browser support
///
/// @return {css} for varaious placeholder rules
@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
}

/// Define keyframes in base and webkit css rules
///
/// @param {string} $keyFrameName
///   The name for the given keyframe definition
/// @return {css} css keyframe definitions with name `$keyFrameName`.
@mixin defineKeyFrames($keyFrameName) {
    @-webkit-keyframes #{$keyFrameName} {
        @content;
    }
    @keyframes #{$keyFrameName} {
        @content;
    }
}

/// Use keyframes with a 1s animation (base and webkit)
///
/// @param {string} $keyFrameName
///   The name for the given keyframe definition
/// @return {css} animation rules using the specified `$keyFrameName`.
@mixin useKeyFrames($keyFrameName) {
    -webkit-animation: #{$keyFrameName} 1s;
    animation: #{$keyFrameName} 1s;
}

/// Set the background colour of a given element, along with darkened background colour on mouseover.
///
/// @param {string} $bg-color
///   The colour to set the background to.
/// @param {boolean} $with-shadow
///   Optional. Default TRUE. If TRUE, add a shadow effect on mouseover effects.
/// @return {css} css for background-color with mouseover transition effects and optional shadow (as per `$with-shadow`).
@mixin hover-effect($bg-color, $with-shadow: true) {
    background-color: $bg-color;
    &:hover:enabled {
        background-color: darken($bg-color, 0%);
        transition: all 0.3s ease;
    }

    &:active:enabled {
        background-color: darken($bg-color, 25%);
        transition: all 0.3s ease;
    }

    @if $with-shadow {
        &:hover:enabled,
        &:active:enabled {
            box-shadow: 0 0 5px 3px $color-grey3;
        }
    }
}

@mixin noSelect() {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
