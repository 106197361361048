@import "../../abstracts/variables";

.bin-note-table {
    table {
        width: 100%;

        .sticky-tr {
            background-color: $color-grey1;
        }
        th,
        td {
            padding: 0;
        }

        th:nth-child(1),
        th:nth-child(3),
        td:nth-child(1),
        td:nth-child(3) {
            width: 40%;
        }
        th:nth-child(3) {
            margin-right: $spacing-large;
        }
    }
}

#event-type-wrapper {
    width: 90%;
}

#event-note-text-input-searchbar {
    width: 90%;
}

#struct-notes-wrapper {
    height: 100px;
}

#create-note-btn {
    position: absolute;
}
