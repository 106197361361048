@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.sitemap {
  height: 100%;
  width: 100%;
  position: relative;

  & > svg {
    height: 100%;
    width: 100%;
    background-color: $color-white;
    background-image: url(../../../images/grid.svg);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1 1 auto;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }
}

.binsenseWatermark {
  pointer-events: none;
  opacity: 0.2;
}

$common-btn-bg-color: $color-light-blue;
$line-width: 2px;

@include defineKeyFrames(move-up) {
  0% {
    bottom: 0px;
  }
  100% {
    bottom: 250px;
  }
}

@include defineKeyFrames(move-down) {
  0% {
    bottom: 250px;
  }
  100% {
    bottom: 0px;
  }
}

@include defineKeyFrames(move-left) {
  0% {
    right: 75px;
  }
  100% {
    right: 605px;
  }
}

@include defineKeyFrames(move-right) {
  0% {
    right: 605px;
  }
  100% {
    right: 75px;
  }
}

.anim-move-up {
  animation: move-up 0.5s forwards;
}

.anim-move-down {
  animation: move-down 0.5s forwards;
}

.anim-move-left {
  animation: move-left 0.5s forwards;
}

.anim-move-right {
  animation: move-right 0.5s forwards;
}

.scale-panel {
  position: absolute;
  right: 0;
  bottom: $spacing-large;

  .scale-btn-container {
    position: absolute;
    right: 0;
    bottom: 0px;
    border-radius: $cornerRadius-normal;
    margin-right: 15px;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    hr {
      color: $color-white;
      width: 95%;
      height: 0;
    }

    #scale-up {
      border-radius: $cornerRadius-normal $cornerRadius-normal 0px 0px;
      margin-bottom: 1px;
    }

    #scale-down {
      border-radius: 0px 0px $cornerRadius-normal $cornerRadius-normal;
      margin-top: 1px;
    }
  }

  .scale-bar-container {
    position: absolute;
    right: 75px;
    bottom: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .scale-bar {
    color: $color-black;
    position: relative;
    right: 0px;
    bottom: 0px;
    height: 37px;
    width: 230px;
    margin-right: 30px;
    display: flex;
    flex-direction: column;

    div:nth-child(1) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-self: center;
      width: 120%;

      span {
        text-align: center;
        align-self: center;
        width: 50px;
      }
    }

    div:nth-child(2) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      height: 100%;

      > div {
        background-color: $color-grey4;
        width: $line-width;
        height: 100%;
      }
    }

    div:nth-child(3) {
      background-color: $color-grey4;
      width: 100%;
      height: $line-width;
    }
  }
}
