@media screen and (orientation: portrait){
  html {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
  }
}

@font-face {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/PublicSans-Regular.ttf');
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Public Sans, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F8FAFC;
}

label {
  font-family: Public Sans
}

code {
  font-family: Public Sans, serif;
}

.apexcharts-svg {
  border-radius: 18px;
  font-family: Public Sans, serif;
}

iframe {
  border-radius: 12px;
}
