@import "../../abstracts/variables";

.serial_hover {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
}

.serial_hover .serial_hover_text {
  visibility: hidden;
  width: 120px;
  background-color: $color-primary;
  color: $color-white;
  text-align: center;
  border-radius: $cornerRadius-small;
  padding: $spacing-small 0;

  /* Position the serial_hover*/
  position: absolute;
  z-index: 1;
}

.serial_hover:hover .serial_hover_text {
  visibility: visible;
}

.batteryIcon {
  width: 45px;
  height: 25px;
}

.disabledSection {
  opacity: 0.4;
  pointer-events: none;
}

#struct-name-label {
  width: 25%;
}

#struct-name-input-wrapper {
  width: 80%;
}

#struct-manual-moisture-input {
  width: inherit;
}

#struct-manual-moisture-label,
#struct-weekly-temp-label,
#struct-min-temp-label,
#struct-moisture-calibration-label {
  width: 100%;
}
