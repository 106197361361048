.struct-edit-form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.struct-name {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > span {
    margin-right: 8px;
  }
}

.struct-form-row {
  margin-top: $spacing-large;
}

.struct-height-details-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.struct-peak-eave-height {
  display: flex;
  flex-direction: column;
}

.struct-diam {
  display: flex;
  flex-direction: row;
  align-items: center;

  > span {
    margin-right: 8px;
  }
}

#struct-edit-form-container {
  .column {
  
    .inputbox-numeric-container {
  
      input {
        padding: $spacing-large 0;
      }
    }
  }
}
