@import '../../abstracts/z-index';
@import '../../abstracts/variables';

#gmap {
  height: 100%;
}
.expanding-to-parent {
  width: 100%;
  height: 100%;
}

.pac-container {
  z-index: $z-gmap-pac-container;
}
#description {
  font-weight: 300;
}

#infowindow-content .title {
  font-weight: bold;
}

#infowindow-content {
  display: none;
}

#gmap #infowindow-content {
  display: inline;
}

.pac-card {
  margin: $spacing-large $spacing-large 0 0;
  border-radius: $cornerRadius-small 0 0 $cornerRadius-small;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  outline: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  background-color: $color-white;
}

#pac-container {
  padding-bottom: 12px;
  margin-right: 12px;
}

.pac-controls {
  display: inline-block;
  padding: $spacing-small 11px;
}

.pac-controls label {
  font-weight: 300;
}

#pac-input {
  margin: 12px;
  background-color: $color-white;
  font-weight: 300;
  height: 10px;
  text-overflow: ellipsis;
  width: 50%;
}

#pac-input:focus {
  border-color: #4d90fe;
}
