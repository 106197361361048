@import '../../abstracts/z-index';
@import '../../abstracts/variables';

.edit-toolbar {
  background-color: $color-light-blue;
  color: $color-white;
  z-index: $z-edit-toolbar;
  display: flex;
  flex-direction: row;

  > div:nth-child(1) {
    height: 180px;
    width: 100%;
  }
}

.edit-toolbar-container {
  display: flex;
  flex-direction: row;
  padding: $spacing-small 0;
  height: 170px;
  width: 800px;

  // Site Tools ()
  .site-tools-container {
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: $spacing-small;
    > * {
      margin: $spacing-small;
    }
  }
  .site-name-container {
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: $spacing-small;

    > * {
      width: 80%;
      height: 10px;
      margin: $spacing-small;
    }
  }
}
.toolbar-section-item {
  width: 100%;
}

.toolbar-section {
  width: 100%;
  height: 35px;
  padding: 3px $spacing-large;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: $color-white;

  span {
    margin-left: 8px;
  }
}

.vline-seperator {
  align-self: center;
  width: 1px;
  height: 90%;
  background-color: $color-grey2;
}

.edit-site-buttons-container {
  max-width: 250px;
  margin-right: $spacing-large;
  height: 100%;

  button {
    width: 100%;
  }
}

.do-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
